import React, { useEffect } from 'react';
import './assets/css/styles2.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Route from './routes/Route'

// import { BottomSheetWatchlist } from './screens/private/watchlist/BottomSheetWatchlist';
// sass --watch src/assets/scss/main.scss:src/assets/css/styles2.css
import { useDispatch, useSelector } from "react-redux";
import { fetchPortfolio } from './store/portfolio/action';
import { fetchCurrencyRates } from './store/currencyRate/action';
import { fetchIndexes } from './store/indexBox/action';
import { fetchProfile } from './store/profile/action';
import { fetchOrder, fetchTrades } from './store/order/action';
import { toast } from 'react-toastify';
// import ReactPixel from 'react-facebook-pixel';

// ReactPixel.init('2093474854359345');

function App() {
  const loginState = useSelector((state) => state.login);
  const socketState = useSelector((state) => state.socket);
  const dispatch = useDispatch();
  // progresser specific
  // useEffect(() => {
  //   // Loop over the time delays from 100ms to 2000ms in increments of 100ms
  //   for (let i = 500; i <= 10000; i += 500) {
  //     setTimeout(() => {
  //       // Find the <a> element with the specific href
  //       const linkElement = document.querySelector('a[href="https://progressier.com?poweredby=true"]');

  //       // If the link is found, hide its parent <div>
  //       if (linkElement) {
  //         const parentDiv = linkElement.closest('div'); // Find the closest parent <div>
  //         if (parentDiv) {
  //           parentDiv.style.display = 'none'; // Hide the parent <div>
  //         }
  //       }
  //     }, i); // Use the current value of i as the delay
  //   }
   
  // }, []); 

  useEffect(() => {
    // Check if ReactNativeWebView is available
    if (window.ReactNativeWebView) {
      const data = {
        isScrolling: true, // Custom data to send
      };
      // Send the message to the WebView
      window.ReactNativeWebView.postMessage(JSON.stringify(data));
    }
  }, []);

  useEffect(() => {
    if (loginState.jwttoken) {
      dispatch(fetchIndexes(loginState.jwttoken));
      dispatch(fetchCurrencyRates(loginState.jwttoken));
      dispatch(fetchPortfolio(loginState.jwttoken));
      dispatch(fetchProfile(loginState.jwttoken));
      setTimeout(() => {
        socketState.notifySocket.emit("authenticate", loginState.jwttoken);
        socketState.notifySocket.on("notify", (data) => {
          if (data.visible === "yes") {
            if (data.type === "success") {
              toast.success(data.message)
            } else {
              toast.error(data.message);
            }
          }
          dispatch(fetchPortfolio(loginState.jwttoken));
          dispatch(fetchOrder(loginState.jwttoken));
          dispatch(fetchTrades(loginState.jwttoken));
        });
      }, 2000)
    }
  }, []);

  // React.useEffect(() => {
  //   ReactPixel.pageView();
  // }, []);

  return (
    <div className="app mb-0">
      {/* <BottomSheetWatchlist /> */}
      <Route />
    </div>
  );
}

export default App;
